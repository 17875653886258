<template>
  <div class="step_container">
     <a-form-model
      ref="person_five_form"
      :model="fiveForm"
      :rules='rules'
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      >
    <h1 class="step_title">POLITICALLY EXPOSED PERSON (“PEP") SELF-CERTIFICATION 政治敏感人物（“PEP”）自我认证</h1>
    <h2 class="step_small_title">
      Under Anti-Money Laundering and Countering Financing of Terrorism Act 2006, Financial Institutions have an obligation to check whether a client is a Politically Exposed Person (PEP).<br>
      Please read definitions below carefully, answer “Yes” or “No” in each case, and sign & date this form. 根据2006年“反洗钱和打击恐怖主义融资法”，金融机构有义务检查客户是否是政治敏感人士（PEP）。 请仔细阅读以下定义，在每种情况下回答“是”或“否”，并在此表格上签名并注明日期。
    </h2>
    <div class="step_question_container">
      <h2 class="step_question_title">
        Do you have: 您是否：
      </h2>
      <span class="claim_text">Do you, or an immediate family member, <i>hold (or did you or they hold at any time in the last 12 months) any of the following positions in any country? 您或直系亲属</i>是否在任何国家/地区担任（或者您在过去12个月内的任何时间持有）以下任何一个职位？</span>
      <div class="step_sub_title mt-10px">Immediate family member means:</div>
      <ul class="step_ul_style">
        <li>a spouse or partner (a person who is considered by relevant national law as equivalent to a spouse);</li>
        <li>a child, and a child’s spouse or partner; or</li>
        <li>a parent.</li>
      </ul>
      <div class="step_sub_title mt-10px">直系亲属为：</div>
      <ul class="step_ul_style">
<li>配偶或伴侣（相关国家法律认为与配偶等同的人）;</li>
        <li>儿童，儿童的配偶或伴侣;</li>
        <li>父母。</li>
        </ul>
      <a-form-model-item
            prop="is_head_of_government"
            label="Head of state or head of a country or government  国家元首或政府首脑"
          >
            <a-radio-group
              class="modify_antd_radio"
              :disabled='disabled'
              @change="handleRadioSelect"
              v-model="fiveForm.is_head_of_government"
              name="is_head_of_government"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        <a-form-model-item
            prop="is_government_minister"
            label="Government minister or equivalent senior politician 政府部长或同等高级政治家"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_government_minister"
              class="modify_antd_radio"
              name="is_government_minister"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_court_judge"
            label="Supreme court judge or equivalent senior judge 最高法院法官或同等高级法官"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_court_judge"
              class="modify_antd_radio"
              name="is_court_judge"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_governor_of_bank"
            label="Governor of a central bank or any other position that has comparable influence to the governor of the Reserve Bank of Australia/New Zealand 中央银行行长或任何其他与澳大利亚/新西兰储备银行行长具有可比影响力的职位"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_governor_of_bank"
              class="modify_antd_radio"
              name="is_governor_of_bank"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_foreign_representative"
            label="Senior foreign representative, ambassador, or high commissioner 高级外国代表，大使或高级专员"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_foreign_representative"
              class="modify_antd_radio"
              name="is_foreign_representative"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_armed_forces"
            label="High-ranking member of the armed forces 武装部队的高级成员"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_armed_forces"
              class="modify_antd_radio"
              name="is_armed_forces"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_chief_executive"
            label="Board chair, chief executive, or chief financial officer of, or any other position that has comparable influence in, any state enterprise 任何国有企业的董事会主席，首席执行官或首席财务官，或任何其他具有类似影响力的职位"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_chief_executive"
              class="modify_antd_radio"
              name="is_chief_executive"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_relative_with_entity"
            label="Joint beneficial ownership of a legal entity or arrangement (e.g. a trust), or any other close relationship, with a person in any of the positions above? 和上述任何职位的人一起拥有法律实体或安排（例如信托）的共同实益所有权或任何与他们有亲近、亲密关系"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_relative_with_entity"
              class="modify_antd_radio"
              name="is_relative_with_entity"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="is_ownership_of_entity"
            label="Sole beneficial ownership of a legal entity or arrangement (e.g. a trust) that to exists for the benefit of a person in any of the positions above? 法律实体或安排（例如信托）的唯一受益所有权者，且为上述任何职位的利益而存在的人？"
          >
            <a-radio-group
              @change="handleRadioSelect"
              :disabled='disabled'
              v-model="fiveForm.is_ownership_of_entity"
              class="modify_antd_radio"
              name="is_ownership_of_entity"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
    </div>
    <div class="step_question_container">
      <h2 class="step_question_title">
        CONFIRMATION 确认书
      </h2>
      <div class="step_sub_title mt-40px">I hereby agree and confirm that:</div>
      <ul class="step_ul_style">
        <li>My answer above is correct.</li>
        <li>If the answer to any category in the future becomes "yes" in the future, I will send a notification immediately.</li>
      </ul>
      <div class="step_sub_title mt-10px">我在此同意并确认：</div>
      <ul class="step_ul_style">
        <li>我上面的答案是正确的。</li>
        <li>如果以后任何类别的答案在将来变为“是”，我会立即发送通知。</li>
        </ul>
        <a-form-model-item
        prop="confirm_checkbox"
        >
       <a-checkbox
       class="antd_checkbox"
      :disabled='disabled'
       name="confirm_checkbox"
       :checked = confirm_checkbox
       @change="handleCheckChange">
          Read & Consent 已读确认
        </a-checkbox>
      </a-form-model-item>
    </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import rules from '@/assets/rules/fifthRules.js'

export default {
  data () {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  created () {
    this.rules = rules
  },
  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
    } else {
      this.$refs.person_five_form.validate(valid => {
        if (valid) {
          next()
        } else {
          next()
        }
      })
    }
  },
  methods: {
    handleCheckChange (e) {
      const { checked, name } = e.target
      this.ChangeFormAction({ content: name, value: checked })
    },
    handleRadioSelect (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },
    ...mapActions('personStepFive', ['ChangeFormAction', 'ChangeObjectAction'])
  },
  computed: {
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    fiveForm () {
      return this.$store.state.personStepFive
    },
    ...mapState('personStepFive', ['is_head_of_government', 'is_government_minister', 'is_court_judge', 'is_governor_of_bank', 'is_foreign_representative', 'is_armed_forces', 'is_chief_executive', 'is_relative_with_entity', 'is_ownership_of_entity', 'confirm_checkbox'])
  }
}
</script>

<style scoped>

.step_container {
  max-width: 940px;
  margin: 0 auto;
  background: rgb(248, 248, 248);
  padding: 0 1rem;

}

.step_small_title{
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}
.claim_text{
  font-weight: 300;
  font-size:17px;
  line-height: 28px;
}
.step_sub_title {
  margin-top: 20px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.step_ul_style {
  list-style-type: inside;
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 24px;
}
.mt-10px{
  margin-top: 10px;
}
.mt-20px{
  margin-top: 20px;
}
.mt-40px{
  margin-top: 40px;
}
.modify_antd_radio >>> .ant-radio-inner{
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after{
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}

.ant-form >>> label{
  font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-wrapper {
  font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-inner{
  width: 30px;
  height: 30px;
}
.antd_checkbox >>> .ant-checkbox-inner::after{
  width: 9px;
  height: 18px;
}
</style>
